<template>
  <v-form @submit.prevent="save_user" ref="user_form">
    <v-row
      class="ma-0 mb-8 pa-2 rounded user_card"
      >
      <v-col cols="12" md="3" v-if="user.name">
        <p class="text-caption mb-0">Name</p>
        <p class="text-subtitle-1 mb-0">{{ user.name }}</p>
      </v-col>
      <v-col cols="12" md="7" v-if="is_invitation">
        <h3>Pending invitation</h3>
        <p class="text-caption">
          This user is invited but have yet not signed up. User will get access to {{ company.name }} when signing up on {{ $appUrl }} using this email-address.
        </p>
      </v-col>
      <v-col cols="12" md="3" v-if="!new_user">
        <p class="text-caption mb-0">Sign in method</p>
        <p class="authenticator text-subtitle-1 mb-0">{{ user.authenticator === 'firebase' ? user.email : user.authenticator }}</p>
      </v-col>
      <v-col cols="12" v-else>
        <v-text-field
          round
          outlined
          v-model="user.email"
          :rules="[v => v && v.length > 3 || 'Required',
                  v => v && v.indexOf('@') !== -1 || 'Invalid email address']"
          label="Email"
          type="email"
          />
      </v-col>
      <v-col cols="12" md="4" v-if="!new_user && !is_invitation">
        <SignatureInput
          :user="user"
         />
      </v-col>
      <v-col class="d-flex justify-md-end" cols="12" md="2" v-if="!new_user">
        <v-btn
          small
          rounded
          v-if="!is_owner(user.id) && !is_self(user.id)"
          :loading="removing_user"
          @click="remove_user(user.id)"
          ><v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-chip v-else-if="is_owner(user.id)">
          Owner
        </v-chip>
        <v-chip v-else>
          You
        </v-chip>
      </v-col>
      <v-col cols="12" v-if="!is_owner(user.id) || new_user">
        <v-select
          v-model="user_scopes"
          :items="scopes"
          :disabled="is_self(user.id)"
          chips
          :rules="[v => v.length > 0 || 'At least one permission required']"
          item-text="text"
          item-value="id"
          label="Permissions"
          @change="save_user_scopes"
          multiple
          >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 3">
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 3"
              class="grey--text text-caption"
            >
              (+{{ user_scopes.length - 3 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" class="text-center" v-if="new_user">
        <v-btn 
          color="primary"
          :loading="saving_user"
          type="submit"
          >Invite</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { mapState } from 'vuex'
import SignatureInput from '@/components/SignatureInput'
// import { mapFields } from 'vuex-map-fields'
// import ToolTip from '@/components/ToolTip.vue'

export default {
  components: {
    SignatureInput,
    
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({
        authenticator: 'firebase',
        email: '',
      })
    },
    new_user: {
      type: Boolean,
      required: false,
      default: false,
    },
    is_invitation: {
      type: Boolean, 
      required: false,
      default: false,
    }
  },
  data(){
    return {
      user_scopes: [],
      saving_user: false,
      removing_user: false,
      scopes: [
        {id: 'fulfill', text: 'Fulfill orders'},
        {id: 'warehouse', text: 'Edit warehouse layout'},
        {id: 'logs', text: 'Logs & Alerts'},
        {id: 'shipments', text: 'Shipments & Returns'},
        {id: 'plans', text: 'Plans and pricing'},
        {id: 'settings.company', text: 'Fulfillment & Product settings'},
        {id: 'settings.stores', text: 'Edit, add and remove Stores and Shipping maps'},
        {id: 'settings.carriers', text: 'Edit, add and remove carriers'},
        {id: 'settings.printers', text: 'Edit printers'},
        {id: 'settings.users', text: 'Edit, add and remove users'},
      ]
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
      userProfile: state => state.user.userProfile,
    }),
    
  },
  methods: {
    save_user_scopes(){
      if( this.user_scopes.length === 0 || this.new_user ) return
      this.$store.dispatch('user/set_user_scopes', { id: this.user.id, scopes: this.user_scopes, is_invitation: this.is_invitation })
    },
    async save_user(){
      if(!this.$refs.user_form.validate()) return
      this.saving_user = true
      try {
        this.user.email = this.user.email.toLowerCase().replace(/ /gi, '')
        const user = { ...this.user, scopes: this.user_scopes }
        await this.$store.dispatch('user/invite_user', user)
        this.saving_user = false
        this.$emit('collapse')
      } catch (e) {
        this.$store.commit('app/SET_SNACK_BAR', e.message)
        this.saving_user = false
      }
    },
    is_owner( id ){
      return this.company.owner === id
    },
    is_self(id){
      return this.userProfile.id === id
    },
    async remove_user( id ){
      this.removing_user = true
      await this.$store.dispatch('warehouse/remove_user', { id, is_invitation: this.is_invitation })
      this.removing_user = false
    },
  },
  mounted(){
    if(!this.new_user){
      this.user_scopes = this.company.users.concat(this.company.invitations)
        .filter(user => user.id === this.user.id)[0]
        .companies
          .filter(company => company.id === this.company.id)[0]
          .scopes
      } else this.user_scopes = ['fulfill']
    }
}
</script>
<style lang="scss" scoped>
    .user_card {
      border: thin solid rgba(0, 0, 0, 0.12);
      }
      p.authenticator:first-letter {
        text-transform:capitalize;
      }
</style>