<template>
  <v-card
  max-width="100%"
  outlined
  class="pb-8"
  >
  <!-- <v-form @submit.prevent="save_users"> -->
    <v-card-title>
      Users & Staff
    </v-card-title>
    <template v-if="!loading">
      <v-card-text>
        <UserCard
          v-for="(user, index) in company.users"
          :key="`user-${index}`"
          :user="user"
          />
        <UserCard
          v-for="(invitation, index) in company.invitations"
          :key="`invitation-${index}`"
          :user="invitation"
          :is_invitation="true"
          />
        <UserCard 
          v-if="add_user"
          :new_user="true"
          @collapse="add_user = false"
          />

      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          @click="toggle_add_user"
          >
          <v-icon v-if="!add_user" left>mdi-plus</v-icon>
          {{ !add_user ? 'Invite user' : 'Cancel' }}
          </v-btn>
          <!-- <v-btn
          color="success"
          type="submit"
          :disabled="disabled_submit"
          >Save</v-btn> -->
      </v-card-actions>
    </template>
    <template v-else>
      <v-card-text class="text-center mt-12">
        <v-progress-circular
          indeterminate
          size="50"
          color="primary"
        ></v-progress-circular>
    </v-card-text>
    </template>
    <!-- <v-card-subtitle>
      Order fulfillment
    </v-card-subtitle> -->
    
    <!-- <v-divider class="mx-4"></v-divider> -->

      <!-- <v-card-actions>
        <v-btn 
          color="success"
          type="submit"
          block
          :disabled="save_disabled"
          :loading="save_loading"
          >
          Save
        </v-btn>
      </v-card-actions>
  </v-form> -->
</v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import UserCard from '@/components/UserCard'
// import { mapFields } from 'vuex-map-fields'
// import ToolTip from '@/components/ToolTip.vue'

export default {
  components: {
    UserCard,
  },
  data(){
    return {
      loading: true,
      add_user: false,
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
    })
  },
  methods: {
    // save_user_scopes({ id }){
    //   const user_index = this.company.users.findIndex(user => user.id === id)
    //   if( this.user_scopes[user_index].length === 0 ) return
    //   this.$store.dispatch('user/set_user_scopes', { id, scopes: this.user_scopes[user_index] })
    // },
    toggle_add_user(){
      if(this.company.users.length + this.company.invitations.length >= this.get_plan.max_staff) {
        return this.$store.commit('app/SET_SNACK_BAR', `Your current plan doesn't allow more staff accounts. Upgrade to add more!`)
      }
      this.add_user = !this.add_user
    },
    remove_user( id ){
      this.$store.dispatch('warehouse/remove_user', id)
    },
  },
  async mounted(){
    await this.$store.dispatch('user/get_company_users')
    this.loading = false

  }
}
</script>
<style lang="scss" scoped>
    .user_card {
      border: thin solid rgba(0, 0, 0, 0.12);
      }
      p.authenticator:first-letter {
        text-transform:capitalize;
      }
</style>