<template>
<v-row no-gutters>
  <template v-if="!image_url && !loading">
    <v-col cols="11">
      <v-file-input
      label="Signature"
      :loading="loading_upload"
      accept="image/png"
      hint="Make sure signature is max 200kb, 350-600px wide, 100-200px high and has transparent background"
      persistent-hint
      @change="on_file_input"
      ref="file_input"
      :error-count="3"
      :error-messages="error_messages"
      validate-on-blur
      prepend-icon="mdi-signature-image"
      :rules="[
          value => !value || value.size < 200000 || 'File size should be less than 200kb',
        ]"
      outlined
      dense
    ></v-file-input>
    </v-col>
    <v-col cols="1">
      <ToolTip>Upload a signature to automatically print on customs declarations. Make sure the image has transparent background.</ToolTip>
    </v-col>
  </template>
  <template v-else-if="image_url">
    <v-col cols="10">
      <!-- <div class="image-container"> -->
      <v-img
        :src="image_url"
        max-height="45px"
        contain
        ></v-img>
        <!-- </div> -->
    </v-col>
    <v-col cols="2">
      <v-btn
        icon
        small
        @click="remove_image"
        >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-col>
  </template>
</v-row>
</template>

<script>

import ToolTip from '@/components/ToolTip'
import firebase from 'firebase/app'
import 'firebase/storage'
import { mapState } from 'vuex'


export default {
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  data: () => {
    return {
      loading_upload: false,
      image_url: '',
      loading: true,
      error_messages: [],
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
    }),
  },
  components: {
    ToolTip
  },
  methods: {
    async on_file_input(file){
      this.error_messages = []
      if(!file) return
      if(!this.$refs.file_input.validate()) return
      const img = new Image()
      const url = window.URL.createObjectURL(file)
      const self = this
      img.onload = async function(){
          if(this.width < 350) self.error_messages.push(`Minimum 350px wide, image is ${this.width}px`)
          if(this.width > 600) self.error_messages.push(`Maximum 600px wide, image is ${this.width}px`)
          if(this.height < 100) self.error_messages.push(`Minimum 100px height, image is ${this.height}px`)
          if(this.height > 200) self.error_messages.push(`Maximum 200px height, image is ${this.height}px`)
          if(self.error_messages.length) return self.loading_upload = false
          await firebase.storage().ref().child(`signatures/${self.company.id}/${self.user.id}.png`).put(file)
          self.image_url = await firebase.storage().ref().child(`signatures/${self.company.id}/${self.user.id}.png`).getDownloadURL()
          self.loading_upload = false
          }
      this.loading_upload = true
      img.src = url
      
    },
    async remove_image(){
      await firebase.storage().ref().child(`signatures/${this.company.id}/${this.user.id}.png`).delete()
      this.image_url = ''
    }
  },
  async mounted(){
    try {
      const image = await firebase.storage().ref().child(`signatures/${this.company.id}/${this.user.id}.png`).getDownloadURL()
      this.image_url = image
      this.loading = false
    } catch (e) {
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .image-container {
    max-height: 45px;
    overflow-y: scroll;
  }
</style>